<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" sm="4">
                <v-card class="card-shadow card-padding">
                    <v-card-title class="pt-0 px-3 card-padding text-h4 text-typo justify-space-between border-bottom">
                        <h2>{{ progetto["nome"] }}</h2>
                        <v-spacer></v-spacer>
                        <small class="text-light">
                            {{ formatDate(progetto["data_creazione"]) }}
                        </small>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn @click="dialogModificaProgetto = true"
                                       elevation="0"
                                       :ripple="false"
                                       x-small
                                       v-bind="attrs"
                                       v-on="on"
                                       class="font-weight-600 text-capitalize btn-ls btn-warning me-2 my-2 rounded-sm"
                                       color="#fb6340"
                                >
                                    <v-icon x-small>fas fa-edit</v-icon>
                                </v-btn>
                            </template>
                            <span>Modifica Dati progetto</span>
                        </v-tooltip>
                    </v-card-title>
                    <v-card-text class="pb-0 d-flex flex-column px-0">
                        <v-container>
                            <v-row>
                                <v-col cols="6">
                                    <small>Codice commessa</small><br />
                                    <h3 style="line-height: 0.5"
                                        @click="copyText(progetto['codice_commessa'])"
                                        class="copy_cursor"
                                    >
                                        {{ progetto["codice_commessa"] }}
                                    </h3>
                                </v-col>
                                <v-col cols="6">
                                    <small>Codice cliente</small><br />
                                    <h3 style="line-height: 0.5"
                                        @click="copyText(progetto['codice_cliente'])"
                                        class="copy_cursor"
                                    >
                                        {{ progetto["codice_cliente"] }}
                                    </h3>
                                </v-col>
                                <v-col cols="12">
                                    <small>Descrizione</small><br />
                                    <h3 style="line-height: 0.5">
                                        {{ progetto["descrizione"] }}
                                    </h3>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" sm="8">
                <v-card :class="$vuetify.breakpoint.mdAndUp ? 'card-shadow py-1' : 'card-shadow'">
                    <div class="display-flex">
                        <v-tabs v-if="!hideTabs"
                                v-model="tabParticolare"
                                align-with-title
                                style="width: 80%"
                                :show-arrows="true"
                                @change="page = 1"
                        >
                            <v-tabs-slider color="primary"></v-tabs-slider>
                            <v-tab v-for="particolare in particolari"
                                   :key="'tab_' + particolare['_id']">
                                {{ particolare["nome"] }}
                            </v-tab>
                        </v-tabs>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon
                                       color="primary"
                                       class="mx-2"
                                       v-bind="attrs"
                                       v-on="on"
                                       @click="dialogNewParticolare = true"
                                       style="width: 10%; margin-top: 7px">
                                    <v-icon>fas fa-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>Aggiungi Particolare</span>
                        </v-tooltip>
                    </div>
                    <v-tabs-items v-model="tabParticolare" v-if="!hideTabs">
                        <v-tab-item v-for="particolare in particolari" :key="particolare['_id']">
                            <v-card flat>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="4" class="pt-2">
                                            <small>Part Number</small><br />
                                            <h3 style="line-height: 0.5"
                                                @click="copyText(particolare['part_number'])"
                                                class="copy_cursor"
                                            >
                                                {{ particolare["part_number"] }}
                                            </h3>
                                        </v-col>
                                        <v-col cols="7" class="pt-2">
                                            <small>Note</small><br />
                                            <h3 style="line-height: 0.5">
                                                {{ particolare["note"] }}
                                            </h3>
                                        </v-col>
                                        <v-col cols="1" class="pt-2">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn @click="showModalSchedaParticolare(particolare)"
                                                           elevation="0"
                                                           :ripple="false"
                                                           x-small
                                                           v-bind="attrs"
                                                           v-on="on"
                                                           class=" font-weight-600 text-capitalize me-2 my-2 rounded-sm"
                                                    >
                                                        <v-icon x-small>fas fa-cog</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Modifica Dati particolare</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" class="pa-0 mt-2">
                                            <v-card-title style="align-items: unset !important;">
                                                <v-row>
                                                    <v-col cols="2">
                                                        <v-select v-model="selectedYear" :items="years" @change="loadData"></v-select>
                                                    </v-col>
                                                    <v-col cols="10">
                                                        <v-text-field
                                                            v-model="search"
                                                            append-icon="mdi-magnify"
                                                            label="Search"
                                                            single-line
                                                            hide-details
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-card-title>
                                            <v-data-table v-if="particolare && particolare['pezzi']"
                                                          no-data-text="Ancora nessun dato"
                                                          :headers="headersParticolari"
                                                          :items="particolare['pezzi']"
                                                          :items-per-page="itemsPerPage"
                                                          :page.sync="page"
                                                          hide-default-footer
                                                          class="table thead-light hideRowsPerPage"
                                                          mobile-breakpoint="0"
                                                          :item-class=" () => { return 'pointer'; } "
                                                          :search="search"
                                                          @page-count="pageCount = $event"
                                            >
                                                <template v-slot:item.seriale="{ item }">
                                                    <span class="ml-2" @click="openPezzo(item)">{{ item.seriale }}</span>
                                                </template>
                                                <template v-slot:item.nonConformi="{ item }">
                                                    <v-chip label
                                                            color="error"
                                                            class="px-2 ml-n3"
                                                            small
                                                            v-if="item.nonConformi">
                                                        <span class="text-white ls-0 text-h4">
                                                            {{item.nonConformi }}
                                                        </span>
                                                    </v-chip>
                                                </template>
                                                <template v-slot:item.nomeOperatoreChiusura="{ item }">
                                                    <span v-if="item.nomeOperatoreChiusura">{{ item.nomeOperatoreChiusura['nome'] }}</span>
                                                </template>
                                                <template v-slot:item.data_completamento="{ item }">
                                                    {{ formatDate(item.data_completamento) }}
                                                </template>
                                                <template v-slot:item.percentuale="{ item }">
                                                    <span class="me-2 text-h6">
                                                        {{ parseInt(item.percentuale || 0) }}%
                                                    </span>
                                                    <v-progress-linear class="progress-shadow"
                                                                       :value="item.percentuale"
                                                                       rounded
                                                                       background-color="LightGrey"
                                                                       :color="item.percentuale === 100 && item.data_completamento ? 'success' : 'error'" />
                                                </template>
                                                <template v-slot:item.visualizza="{ item }">
                                                    <span @click="openPezzo(item)">
                                                        <i class="fas fa-eye"></i>
                                                    </span>
                                                </template>
                                                <template v-slot:item.stampa="{ item }">
                                                    <span @click="openPrintDialog(item)">
                                                        <i class="fas fa-print"></i>
                                                    </span>
                                                </template>
                                            </v-data-table>
                                        </v-col>
                                        <v-col cols="12" class="pa-0 mt-2">
                                            <v-pagination v-model="page" :length="pageCount" :total-visible="7" />
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="dialogModificaProgetto"
                  content-class="position-absolute top-0"
                  :width="$vuetify.breakpoint.mdAndUp ? '65%' : '90%'"
        >
            <ModalModificaProgetto v-if="dialogModificaProgetto"
                                   :progettoProp="progetto"
                                   @chiudiDialogModificaProgetto="chiudiDialogModificaProgetto"/>
        </v-dialog>
        <v-dialog v-model="dialogNewParticolare"
                  persistent
                  content-class="position-absolute top-0"
                  :width="$vuetify.breakpoint.mdAndUp ? '65%' : '90%'"
        >
            <NewParticolare v-if="dialogNewParticolare"
                            :progettoProp="progetto"
                            @chiudiDialogNewParticolare="chiudiDialogNewParticolare"/>
        </v-dialog>
        <v-dialog v-model="dialogSchedaParticolare"
                  persistent
                  content-class="position-absolute top-0"
                  :width="$vuetify.breakpoint.mdAndUp ? '65%' : '90%'"
        >
            <SchedaParticolare v-if="dialogSchedaParticolare && progetto && particolareClicked"
                               :id_progetto="progetto['_id']"
                               :particolareProp="particolareClicked"
                               :indexParticolare="indexParticolareClicked"
                               @chiudiDialogSchedaParticolare="chiudiDialogSchedaParticolare"/>
        </v-dialog>
    </v-container>
</template>

<script>
import apiProgetti from "@/js/pages/progetti";
import apiPezzi from "@/js/pages/pezzi";
import {momentDate} from "@/js/formatDate";
import ModalModificaProgetto from "@/components/ModalModificaProgetto.vue";
import NewParticolare from "@/components/NewParticolare.vue";
import SchedaParticolare from "@/components/SchedaParticolare.vue";
import {emailValidaRules, matricolaRules, nomeRules, requiredRules,} from "@/js/validationRules";

export default {
    name: "schedaProgetto",
    data() {
        return {
            search: '',
            token: this.$cookies.get("token_goldencar"),
            id: this.$route.query._id,
            email: "",
            loading: false,
            progetto: {},
            formValid: false,
            emailRules: emailValidaRules,
            nomeRules: nomeRules,
            matricolaRules: matricolaRules,
            requiredRules: requiredRules,
            isEditing: false,
            tabParticolare: undefined,
            particolari: [],
            dialogModificaProgetto: false,
            dialogNewParticolare: false,
            dialogSchedaParticolare: false,
            particolareClicked: undefined,
            indexParticolareClicked: 0,
            pagesParticolari: [],
            itemsPerPage: 10,
            headersParticolari: [
                {
                    text: "Seriale",
                    align: "start",
                    sortable: true,
                    value: "seriale",
                    width: "15%",
                },
                { text: "Data Compl", value: "data_completamento", width: "15%" },
                { text: "Operatore", value: "nomeOperatoreChiusura", width: "15%" },
                { text: "Note", value: "note", width: "20%" },
                {
                    text: "N. Non Conf",
                    value: "nonConformi",
                    width: "10%",
                    align: "center",
                },
                { text: "Percentuale", value: "percentuale", width: "10%" },
                { text: "Visualizza", value: "visualizza", width: "10%" },
                { text: "Stampa", value: "stampa", width: "10%" },
            ],
            hideTabs: true,
            listaVistePezzi: [],
            page: 1,
            pageCount: 0,
            selectedYear: new Date().getFullYear(),
            years: []
        };
    },
    components: {
        ModalModificaProgetto,
        NewParticolare,
        SchedaParticolare,
    },
    async created() {
        const currentYear = new Date().getFullYear();
        const startYear = 2020;
        const years = [];
        for (let year = startYear; year <= currentYear; year++)
            years.push(year);

        years.reverse();
        this.years = years;

        this.Socket.emit("getListaVistePezzi");
        this.Socket.on("listaVistePezzi", (data) => {
            this.listaVistePezzi = data;
        });
        await this.loadData();
    },
    methods: {
        formatDate(date) {
            if (date) return momentDate(date);
            return "";
        },
        async chiudiDialogModificaProgetto() {
            this.loadData();
            this.dialogModificaProgetto = false;
        },
        chiudiDialogNewParticolare() {
            this.loadData();
            this.dialogNewParticolare = false;
        },
        async loadData() {
            let progetto = await apiProgetti.getOne(this.token, this.id);
            this.hideTabs = true;
            this.progetto = progetto || {};

            for (let particolare of this.progetto['particolari']) {
                let pezzi = await apiPezzi.getMultiple(this.token, {
                    token: this.token,
                    year: this.selectedYear,
                    _id: particolare["pezzi"]
                });

                particolare['pezzi'] = pezzi;
                for (let pezzo of pezzi || []) {
                    if (pezzo) {
                        pezzo["percentuale"] = await this.calculatePercentage(pezzo);
                        pezzo["nonConformi"] = (pezzo["passi"] || []).filter(
                            (x) => x["conforme"] === false
                        ).length;
                    }
                }
            }
            if (
                progetto.particolari &&
                progetto.particolari.length
            ) progetto["particolari"] = progetto["particolari"].sort((a, b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));

            this.particolari = progetto["particolari"] || [];
            setTimeout(() => {
                this.hideTabs = false;
            }, 5);
        },
        copyText(value) {
            var textArea = document.createElement("textarea");
            textArea.style.position = "fixed";
            textArea.style.top = 0;
            textArea.style.left = 0;
            textArea.style.width = "2em";
            textArea.style.height = "2em";
            textArea.style.padding = 0;
            textArea.style.border = "none";
            textArea.style.outline = "none";
            textArea.style.boxShadow = "none";
            textArea.style.background = "transparent";
            textArea.value = value;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                document.execCommand("copy");
            } catch (err) {
                console.log("Impossibile copiare nella clipboard", err);
            }

            document.body.removeChild(textArea);
            this.EventBus.$emit("showAlert", ["Testo copiato negli appunti"]);
        },
        showModalSchedaParticolare(particolare) {
            this.particolareClicked = particolare;
            this.dialogSchedaParticolare = true;
        },
        async chiudiDialogSchedaParticolare() {
            await this.loadData();
            this.particolareClicked = undefined;
            this.dialogSchedaParticolare = false;
        },
        calculatePercentage(pezzo = {}) {
            let numPezziConformi = 0;
            for (let passo of (pezzo["passi"] || [])) {
                if (
                    passo &&
                    passo["conforme"] &&
                    (!passo["requireImage"] ||
                        (passo["requireImage"] && passo["image_name"]))
                )
                    numPezziConformi += 1;
            }
            return (numPezziConformi * 100) / (pezzo["passi"] || []).length;
        },
        openPezzo(pezzo) {
            if (pezzo["_id"]) {
                if (this.listaVistePezzi.indexOf(pezzo["_id"]) === -1)
                    this.$router.push("/pezzoOperatore?_id=" + pezzo["_id"]);
                else
                    alert("Impossibile aprire, qualcun'altro sta modificando il pezzo!");
            }
        },
        openPrintDialog(item) {
            window.open("/s/" + item["_id"]);
        }
    }
};
</script>
