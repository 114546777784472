<template>
  <v-card :class="$vuetify.breakpoint.mdAndUp ? 'card-shadow card-padding' : 'card-shadow'">
    <v-card-title
      class="
        pt-0
        px-3
        card-padding
        text-h4 text-typo
        justify-space-between
        border-bottom
      "
    >
      <h2>Nuovo Particolare</h2>
      <v-spacer></v-spacer>
      <v-btn small icon @click="$emit('chiudiDialogNewParticolare')">
        <v-icon size="20" class="text-typo">fas fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="pb-0 d-flex flex-column px-0">
      <v-form
        @submit="submit"
        v-model="formValid"
        ref="form_data"
        autocomplete="off"
        lazy-validation
      >
        <v-card class="card-shadow">
          <v-card-text class="pa-6 pt-3 text-center font-size-root">
            <label
              class="label-color font-weight-600 d-block text-left text-h5 mt-2"
              >Part Number</label
            >
            <v-text-field
              hide-details="auto"
              required
              :rules="requiredRules"
              outlined
              background-color="rgba(255,255,255,.9)"
              light
              placeholder="Part Number"
              autocomplete="off"
              v-model="partNumber"
              class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
                mb-4
              "
            ></v-text-field>

            <label
              class="label-color font-weight-600 d-block text-left text-h5 mt-2"
              >Nome</label
            >
            <v-text-field
              hide-details="auto"
              required
              :rules="nomeRules"
              outlined
              background-color="rgba(255,255,255,.9)"
              light
              placeholder="Nome"
              autocomplete="off"
              v-model="nome"
              class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
                mb-4
              "
            ></v-text-field>

            <label
              class="label-color font-weight-600 d-block text-left text-h5 mt-2"
              >Note</label
            >
            <v-textarea
              hide-details="auto"
              outlined
              auto-grow
              rows="2"
              background-color="rgba(255,255,255,.9)"
              light
              placeholder="Note"
              autocomplete="off"
              v-model="note"
              class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
                mb-4
              "
            ></v-textarea>

            <Passi :passi="passiParticolare" :isEditing="true"  @listChanged="listChanged"/>

            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-600
                text-capitalize
                btn-primary
                py-3
                px-6
                rounded-sm
                my-1
              "
              color="primary"
              type="submit"
              >Crea</v-btn
            >
          </v-card-text>
        </v-card>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import apiParticolari from "@/js/pages/particolari";
import { nomeRules, requiredRules } from "@/js/validationRules";
import Passi from "@/components/Passi.vue";

export default {
  name: "newParticolare",
  props: ["progettoProp"],
  data() {
    return {
      token: this.$cookies.get("token_goldencar"),
      this_ruolo: this.$store.state.ruolo,
      nome: "",
      partNumber: "",
      note: "",
      passiParticolare: [],
      formValid: false,
      nomeRules: nomeRules,
      requiredRules: requiredRules,
      loading: false,
    };
  },
  components: {
    Passi
  },
  methods: {
    async submit(e) {
      e.preventDefault();
      if(!this.loading) {
        this.loading = true;
        if (this.$refs.form_data.validate() && this.formValid) {
          let data = {
            id_progetto: this.progettoProp["_id"],
            nome: this.nome,
            partNumber: this.partNumber,
            note: this.note,
            passi: this.passiParticolare,
          };
          let newProgetto = await apiParticolari.insertNew(this.token, data);
          if (newProgetto) this.$emit("chiudiDialogNewParticolare");
          else alert("Errori durante la creazione, ricontrolla i dati");
        }
        this.loading = false;
      }
    },
    listChanged(list) {
      this.passiParticolare = list;
    }
  },
};
</script>
